//TODO: implement
import './profile.css';

import  { useEffect, useState }  from 'react'

import {Bio} from './Components/Bio';
import {HeaderImage} from './Components/HeaderImage';
import {InterestSet} from './Components/InterestSet';
import {LinkSet} from './Components/LinkSet';
import {MetaTags} from './Components/Meta';
import {NameData} from './Components/NameData';
import {ProfileImage} from './Components/ProfileImage';
import axios from 'axios'
import env from "react-dotenv";

const YODAA_LOGO = 'https://res.cloudinary.com/atlantiscapital/image/upload/v1608288511/yodaa_static/public_profile/vuhwlurh78d5yk3vccfo.png';

const getPublicProfile = async () => {
    let username =  window.location.href.split('/').pop()
    if (username === '') {
        window.location.href = 'https://yodaa.club';
        return false
    }
    let url = `${env.ROOT}/${username}`;
    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*'
        }
    }
    try {
       let response = await axios.get(url, config);
       console.log(response.data.details)
       return response.data.details; 
    } catch (error) {
        console.log(error);
        console.log(error.response);
        window.location.href = 'https://yodaa.club';
        return false;
    }
}


const ProfilePage = () => {
    const [profile, setProfile] = useState(); 
    useEffect(()=>{
        let mounted = true;
        async function getData() {
            if (!profile) {
                let data = await getPublicProfile(); 
                console.log("DATA", data)
               setProfile(data);
           }
        }
        getData()
       
        return () => mounted = false;
    },[profile])

    if (!profile) {
        return null;
    }
    return (<div className="pure-g">

        <div className="pure-u-0 pure-u-md-1-4"></div>
        <div className="pure-u-1 pure-u-md-1-2">
            <MetaTags 
                title={`${profile?.user?.first_name} ${profile?.user?.last_name}`}
                image={profile?.user?.user_image_url}
                description={profile?.bio}
            />
            <div className="profile-hero">

            
    
       <HeaderImage src={profile?.cover_image}/>
       <ProfileImage src={profile?.user?.user_image_url} />
       <NameData name={`${profile?.user?.first_name} ${profile?.user?.last_name}`} username={`@${profile?.profile_name}`} memberSince={profile?.user?.user_since}/>
       <LinkSet socials={profile?.social_link ?? []}/>
       <Bio bio={profile?.bio} />
       <InterestSet interests={profile?.interests ??[]} />
       </div>
       <div className="logo-holder">
           <a href="https://yodaa.club" target="_blank" rel="noreferrer">
            <img className="logo-file" src={YODAA_LOGO} alt="yodaa" />
            </a>

       </div>
     </div>
     <div className="pure-u-0 pure-u-md-1-4"></div>

     
    </div>)
}


export {ProfilePage}