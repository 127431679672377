import './index.css';

const DEFAULT_PROFILE = "https://res.cloudinary.com/atlantiscapital/image/upload/v1606978655/yodaa_static/public_profile/se0ihfkimus0guhqc7ld.png"

const ProfileImage = (props) => {
    return (<div className="profile-image">
        <img src={props.src ?? DEFAULT_PROFILE} alt="profile" className="profile-image-file"/>
    </div>)
}

export {ProfileImage}