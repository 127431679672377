import './index.css';

const LinkSet = (props) => {
    return (<div className="link-set">
        <div>
            {props.socials.map((s,i) => {
                return (
                    <div className="social-link">
                        <a href={s.link} target="_blank">
                        <img src={s.logo} alt="logo" className="social-logo" title={s.domain === 'default_no_match' ? 'Web' : s.domain} />
                        </a>
                    </div>
                )
            })}
        </div>
    </div>)
}

export {LinkSet}