import './index.css';

const formatDate = (date) => {
let _date = new Date(date);
let [a, month, b, year] = _date.toDateString().split(" ")

return `${month}, ${year}`

}

const NameData = (props) => {
    return (<div className="name-data">
        <p>
            <span className="name">{props.name}</span><br />
            <span className="username">{props.username}</span><br />
            <span className="member-since">Member since {formatDate(props.memberSince)}</span><br />
        </p>
    
    </div>)
}

export {NameData}