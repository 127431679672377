import {Helmet} from 'react-helmet'; //

const MetaTags = (props) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
            <meta name="twitter:card" value={props.description} />
            <meta property="og:title" content={props.title} />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={props.username} />
            <meta property="og:image" content={props.image} />
            <meta property="og:description" content={props.description} />
        </Helmet>
    )
}


export {MetaTags}