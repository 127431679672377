import './index.css';

import {Bubble} from './Bubble';

const InterestSet = (props) => {
    return (<div className="interest-set">
         <p className="interest-heading">Interests</p>
         <div className="interest-list">
       {props.interests.map((s,i)=> <Bubble key={i} title={s} />)}

       <div className="clearboth" />
       </div>
    </div>)
}

export {InterestSet}