import {ProfilePage} from './screens/Profile'

function App() {
  return (
    <div className="app-container">
     <ProfilePage />
    </div>
  );
}

export default App;
