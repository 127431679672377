import './index.css';

const Bio = (props) => {
    return (<div className="bio">
        <p>
            
            <p className="bio-heading">Bio</p>
             
            <span className="bio-data">{props.bio}</span>

        </p>
    
    </div>)
}


export {Bio}